<template>

	<!--<div class="mobile-views-validate-po-details-list">
		<div class="card" v-for="(item, i) in items" :key="i" @click="handleCardClick">
			<div class="card-body">
				<div class="head">
					<h6>{{ item.name }}</h6>
				</div>
				<div class="content">
					<div class="info">
						<span>
							{{ FormMSG(3, 'Category') }}: <b>{{ item.categoryName }}</b>
						</span>
						<br />
						<span>
							{{ FormMSG(4, 'Description') }}: <b>{{ item.description }}</b>
						</span>
					</div>
					<div class="price">
						<span>
							{{ FormMSG(1, 'Price:') }}: <b>{{ rendPrice(item.price) }}</b>
						</span>
						<br />
						<span>
							{{ FormMSG(2, 'Total:') }}: <b>{{ rendTotal(item.total) }}</b>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>-->

	<CardListBuilder :use-new-version="true" :items="items" :fields="fields" :hide-status="false" :toggle-mode="true"> </CardListBuilder>

</template>

<script>
import { rendCurrency } from '~helpers';
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'MobileViewsValidatePoDetailsListComponent',
	mixins: [languageMessages],
	props: {
		items: {
			type: Array,
			required: true,
			default: () => []
		},
		fields: {
			type: Array,
			required: true,
			default: () => []
		}
	},
	methods: {
		handleCardClick() {
			this.$emit('card-clicked', this.item);
		},

		/**
		 * @param {Number} price
		 * @return {String}
		 */
		rendPrice(price) {
			return rendCurrency(price);
		},
		/**
		 * @param {Number} total
		 * @return {String}
		 */
		rendTotal(total) {
			return rendCurrency(total);
		}
	}
};
</script>

<style lang="scss" scoped>
.head,
.content {
	width: 100%;
}
.head {
	padding-bottom: 10px;
}
.content {
	display: flex;
	justify-content: space-between;
}
</style>
