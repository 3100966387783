var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("CardListBuilder", {
    attrs: {
      "use-new-version": true,
      items: _vm.items,
      fields: _vm.fields,
      "hide-status": false,
      "toggle-mode": true,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }