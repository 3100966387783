var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c(
        "div",
        {
          staticClass: "form animated fadeIn p-0",
          class: {
            "container-layout": _vm.$screen.width >= 992,
            "container-mobile": _vm.$screen.width < 992,
          },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "h1",
                    {
                      class: [
                        `${
                          !_vm.$screen.sm && !_vm.$screen.md
                            ? "main-page-title"
                            : ""
                        }`,
                        { "is-pwa": _vm.$isPwa() },
                      ],
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(215, "Purchase Order Details")) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-card",
                    {
                      staticStyle: { "background-color": "#fff" },
                      attrs: { "no-body": "" },
                    },
                    [
                      _vm.$screen.width >= 992
                        ? _c(
                            "b-row",
                            {
                              staticClass:
                                "back-with-title cursor-pointer d-flex align-items-center pt-0 pb-2 pl-0",
                              class: { "mt-16": _vm.$isPwa() },
                              staticStyle: { "margin-top": "-6px" },
                            },
                            [
                              _c("b-col", { attrs: { cols: "6" } }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-transparent",
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.back()
                                      },
                                    },
                                  },
                                  [
                                    _c("ArrowLeft", {
                                      staticClass: "icon",
                                      attrs: {
                                        size: 22,
                                        color: "rgba(6, 38, 62, 0.65)",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "title-back-bolder mx-0" },
                                      [_vm._v(_vm._s(_vm.caption1))]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "inline-flex align-items-center text-right py-0",
                                  attrs: { cols: "6" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "date-title pr-3" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "wrap-status justify-content-end",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              class: `status ${_vm.classStatus(
                                                _vm.curPo.validated
                                              )}`,
                                              staticStyle: {
                                                "font-size": "0.825rem",
                                                border:
                                                  "0.005rem solid rgba(226, 230, 235, 0.65)",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.curPo.validatedStatus
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "name-title pr-3" },
                                    [
                                      _vm._v(_vm._s(_vm.curPo.fullName) + " "),
                                      _c("span", {
                                        staticClass: "divider-dot",
                                      }),
                                      _vm._v(
                                        " " + _vm._s(_vm.curPo.functionName)
                                      ),
                                    ]
                                  ),
                                  _vm.useEntryForCrewPo
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center justify-content-end w-100 pr-3",
                                        },
                                        [
                                          !_vm.isFilmSingle && _vm.curPo.encoder
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "border-encoded p-1",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        40,
                                                        "Encoded by: "
                                                      )
                                                    )
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.curPo.encoder
                                                          .firstName
                                                      ) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.curPo.encoder.name
                                                        )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "header-name-title pr-3" },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.dateToStringFormat(
                                              _vm.curPo.requestDate
                                            )
                                          ) +
                                          " "
                                      ),
                                      _c("span", {
                                        staticClass: "divider-dot",
                                      }),
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatAmount(_vm.curPo.amount)
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "header-name-title pr-3" },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(_vm.FormMSG(875, "N°")) + ":"
                                        ),
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.curPo.poReference) +
                                          "\n\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c("div", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "back-with-title d-flex align-items-center cursor-pointer",
                                class: { "mt-16": _vm.$isPwa() },
                                on: {
                                  click: function ($event) {
                                    return _vm.$router.back()
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "icon" },
                                  [
                                    _c(_vm.getLucideIcon("ArrowLeft"), {
                                      tag: "component",
                                      attrs: { color: "#667987", size: 22 },
                                    }),
                                  ],
                                  1
                                ),
                                _c("h2", [_vm._v(_vm._s(_vm.caption1))]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "header-title-exp-detail text-center py-2",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "header-date-title pr-3" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "wrap-status justify-content-end",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            class: `status ${_vm.classStatus(
                                              _vm.curPo.validated
                                            )}`,
                                            staticStyle: {
                                              "font-size": "0.7rem",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.labelStatus(
                                                    _vm.curPo.validated
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "header-name-title pr-3" },
                                  [
                                    _vm._v(_vm._s(_vm.curPo.fullName) + " "),
                                    _c("span", { staticClass: "divider-dot" }),
                                    _vm._v(
                                      " " + _vm._s(_vm.curPo.functionName)
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "header-name-title pr-3" },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.dateToStringFormat(
                                            _vm.curPo.requestDate
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("span", { staticClass: "divider-dot" }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatAmount(_vm.curPo.amount)
                                        ) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                      _c(
                        "b-card-text",
                        {
                          class: `${
                            _vm.$screen.width >= 992 ? "px-3 pt-4" : "px-2 pt-3"
                          }`,
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "card card-border-blue-light mt-3",
                              style: `${
                                _vm.$screen.width <= 992
                                  ? "margin-bottom: 112px;"
                                  : ""
                              }`,
                            },
                            [
                              _c(
                                "b-row",
                                [
                                  _vm.curPo.images &&
                                  _vm.curPo.images.length > 0
                                    ? _c(
                                        "b-col",
                                        {
                                          staticClass: "pt-28",
                                          attrs: { md: "3" },
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                block: "",
                                                variant: "outline-success",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showPicture(
                                                    _vm.curPo.images
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(_vm.getLucideIcon("Image"), {
                                                tag: "component",
                                                attrs: { size: 16 },
                                              }),
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    `${_vm.FormMSG(
                                                      425,
                                                      "View"
                                                    )} ${
                                                      _vm.curPo.images.length
                                                    } ${_vm.FormMSG(
                                                      428,
                                                      "pictures"
                                                    )}`
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _vm.curPo.comment
                                        ? _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  23,
                                                  "Comment:"
                                                ),
                                                "label-for": "comment",
                                              },
                                            },
                                            [
                                              _c("b-form-textarea", {
                                                attrs: {
                                                  disabled: true,
                                                  id: "comment",
                                                  rows: "2",
                                                },
                                                model: {
                                                  value: _vm.curPo.comment,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.curPo,
                                                      "comment",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "curPo.comment",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                {
                                  staticClass: "pt-3",
                                  staticStyle: { "padding-bottom": "25vh" },
                                },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12", xl: "12" } },
                                    [
                                      _vm.$screen.width < 992
                                        ? _c(
                                            "MobileViewsValidatePoDetailsList",
                                            {
                                              attrs: {
                                                items: _vm.PoItems,
                                                fields: _vm.poItemFields,
                                              },
                                              on: {
                                                "card-clicked": _vm.rowClicked,
                                              },
                                            }
                                          )
                                        : _vm._e(),
                                      _vm.$screen.width >= 992
                                        ? _c("b-table", {
                                            staticStyle: {
                                              "text-align": "center",
                                            },
                                            attrs: {
                                              hover: _vm.hover,
                                              responsive: "sm",
                                              items: _vm.PoItems,
                                              fields: _vm.poItemFields,
                                              "current-page": _vm.currentPage,
                                              "sticky-header": "700px",
                                              "per-page": _vm.perPage,
                                              "head-variant": _vm.hv,
                                              bordered: "",
                                              small: "",
                                            },
                                            on: {
                                              "row-clicked": _vm.rowClicked,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                {
                                  class: `${
                                    _vm.$screen.width <= 576
                                      ? "footer-fixed"
                                      : ""
                                  }`,
                                },
                                [
                                  _vm.canEditPo
                                    ? _c(
                                        "b-col",
                                        {
                                          staticClass: "mb-3",
                                          attrs: { lg: "2" },
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "d-flex justify-content-center align-items-center",
                                              attrs: {
                                                variant: "outline-primary",
                                                block: "",
                                              },
                                              on: {
                                                click: _vm.handleClickEditPO,
                                              },
                                            },
                                            [
                                              _c(
                                                _vm.getLucideIcon(
                                                  _vm.ICONS.EDIT.name
                                                ),
                                                {
                                                  tag: "component",
                                                  staticClass: "mr-2",
                                                  attrs: { size: 20 },
                                                }
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "2px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        168,
                                                        "Edit Purchase Order"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-col",
                                    { staticClass: "mb-3", attrs: { lg: "3" } },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "d-flex justify-content-center align-items-center mr-3",
                                          attrs: {
                                            block: "",
                                            variant: "outline-primary",
                                          },
                                          on: { click: _vm.downloadXls },
                                        },
                                        [
                                          _c(
                                            _vm.getLucideIcon(
                                              _vm.ICONS.DOWNLOAD.name
                                            ),
                                            {
                                              tag: "component",
                                              staticClass: "mr-2",
                                              attrs: { size: 20 },
                                            }
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-top": "1px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  this.FormMSG(
                                                    222,
                                                    "Download this Purchase Order"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { staticClass: "mb-3", attrs: { lg: "3" } },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "d-flex justify-content-center align-items-center",
                                          attrs: {
                                            block: "",
                                            variant: "outline-primary",
                                            disabled: _vm.isLoadingSendMail,
                                          },
                                          on: { click: _vm.sendXlsByEmail },
                                        },
                                        [
                                          _c("b-spinner", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.isLoadingSendMail,
                                                expression: "isLoadingSendMail",
                                              },
                                            ],
                                            staticClass: "mr-2",
                                            attrs: { small: "" },
                                          }),
                                          !_vm.isLoadingSendMail
                                            ? _c(
                                                _vm.getLucideIcon(
                                                  _vm.ICONS.SEND.name
                                                ),
                                                {
                                                  tag: "component",
                                                  staticClass: "mr-2",
                                                  attrs: { size: 20 },
                                                }
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-top": "1px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    500,
                                                    "Send me this purchace order by Email"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { staticClass: "mb-3", attrs: { lg: "2" } },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "d-flex justify-content-center align-items-center",
                                          attrs: {
                                            size: "md",
                                            variant: "outline-success",
                                            block: "",
                                          },
                                          on: { click: _vm.validatePO },
                                        },
                                        [
                                          _c(
                                            _vm.getLucideIcon(
                                              _vm.ICONS.CHECK_SQUARE.name
                                            ),
                                            {
                                              tag: "component",
                                              staticClass: "mr-2",
                                              attrs: { size: 20 },
                                            }
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-top": "1px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  this.FormMSG(
                                                    24,
                                                    "Validate Purchase Order"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { lg: "2" } },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "d-flex justify-content-center align-items-center",
                                          attrs: {
                                            size: "md",
                                            variant: "outline-danger",
                                            block: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.ModalRejectPO = true
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            _vm.getLucideIcon(
                                              _vm.ICONS.X_CIRCLE.name
                                            ),
                                            {
                                              tag: "component",
                                              staticClass: "mr-2",
                                              attrs: { size: 20 },
                                            }
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-top": "1px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  this.FormMSG(
                                                    25,
                                                    "Reject Purchase Order"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-modal",
                            {
                              staticClass: "modal-success",
                              attrs: {
                                "header-class":
                                  "header-class-modal-doc-package",
                                title: _vm.FormMSG(26, "Success!"),
                                "ok-variant": "success",
                                "ok-only": "",
                              },
                              on: {
                                ok: function ($event) {
                                  _vm.successModalValidated = false
                                },
                              },
                              model: {
                                value: _vm.successModalValidated,
                                callback: function ($$v) {
                                  _vm.successModalValidated = $$v
                                },
                                expression: "successModalValidated",
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(
                                    this.FormMSG(
                                      27,
                                      "The purchase order has been validated"
                                    )
                                  ) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _c(
                            "b-modal",
                            {
                              staticClass: "modal-danger",
                              attrs: {
                                "header-class":
                                  "header-class-modal-doc-package",
                                title: _vm.FormMSG(
                                  28,
                                  "Refuse this purchase order"
                                ),
                                "ok-variant": "danger",
                                "ok-only": "",
                              },
                              on: { ok: this.finaliseExpenseReject },
                              model: {
                                value: _vm.ModalRejectPO,
                                callback: function ($$v) {
                                  _vm.ModalRejectPO = $$v
                                },
                                expression: "ModalRejectPO",
                              },
                            },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(
                                      29,
                                      "Enter your comment:"
                                    ),
                                    "label-for": "comment",
                                    "label-cols": 4,
                                  },
                                },
                                [
                                  _c("b-form-textarea", {
                                    attrs: {
                                      id: "comment",
                                      placeholder: this.FormMSG(
                                        30,
                                        "Enter your comment"
                                      ),
                                      rows: "3",
                                    },
                                    model: {
                                      value: _vm.rejectComment,
                                      callback: function ($$v) {
                                        _vm.rejectComment = $$v
                                      },
                                      expression: "rejectComment",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("PoService", {
                            attrs: {
                              "edit-data": _vm.poToEdit,
                              "for-screen-validation": "",
                            },
                            on: {
                              closed: _vm.handlePOServiceClosed,
                              submitted: _vm.handlePOServiceSubmitted,
                              refresh: _vm.handlePOServiceRefresh,
                            },
                            model: {
                              value: _vm.showPoService,
                              callback: function ($$v) {
                                _vm.showPoService = $$v
                              },
                              expression: "showPoService",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-success",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: _vm.FormMSG(21211, "Success!"),
            "ok-variant": "success",
            "ok-only": "",
          },
          on: {
            ok: function ($event) {
              _vm.successModal = false
            },
          },
          model: {
            value: _vm.successModal,
            callback: function ($$v) {
              _vm.successModal = $$v
            },
            expression: "successModal",
          },
        },
        [
          _vm._v(
            "\n\t\t" +
              _vm._s(
                _vm.FormMSG(
                  601,
                  "The purchace order has been sent to your Email address"
                )
              ) +
              "\n\t"
          ),
        ]
      ),
      _c("script-export-modal", {
        attrs: {
          items: _vm.scripts,
          custom: _vm.customReport,
          "export-type": "po",
        },
        on: {
          "script-export-modal:loading": _vm.handleMailSent,
          "script-export-modal:closed": _vm.poRerpotOnClosed,
        },
        model: {
          value: _vm.isScriptPoReportOpen,
          callback: function ($$v) {
            _vm.isScriptPoReportOpen = $$v
          },
          expression: "isScriptPoReportOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }